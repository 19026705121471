












import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import VesselEnquiryActiveTable from "@/components/vessel/enquiry/VesselEnquiryActiveTable.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { VesselEnquiryModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { VesselEnquiryActiveTable } })
export default class VesselEnquiryOpenOverview extends Vue {
  private tableRefreshKey = false;

  private async onUpdateVesselEnquiry(item: VesselEnquiryModel) {
    if (!item.vesselEnquiryId) {
      return;
    }

    this.$router.push({
      name: "Vessel Enquiry Edit",
      params: { vesselEnquriyId: item.vesselEnquiryId }
    });
  }

  private async onDeleteVesselEnquiry(item: VesselEnquiryModel) {
    if (!item.vesselEnquiryId) {
      return;
    }

    try {
      await Api.VesselEnquiryService.apiVesselenquiryVesselEnquiryIdDelete(
        item.vesselEnquiryId
      );
      snackbarModule.setSnackbarMessage("Vessel enquiry deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete vessel enquiry");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
}
